import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introdução",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#introdu%C3%A7%C3%A3o",
        "aria-label": "introdução permalink",
        "className": "anchor before",
        "parentName": "h1"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Introdução`}</h1>
    <p>{`Bem-vindo ao After School Online!`}</p>
    <p>{`Neste site, vais ter acesso às tarefas que tens de realizar em casa, durante a semana,
`}
      <strong {...{
        "parentName": "p"
      }}>{`antes`}</strong>
      {` da aulas com os instrutores. Para que seja mais fácil orientares-te, vamos dar-te
um conjunto de indicações caso:`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Ainda não estejas registado neste site`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Já estejas registado neste site`}</li>

    </ul>
    <h2 {...{
      "id": "se-ainda-não-estás-registado-neste-site",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#se-ainda-n%C3%A3o-est%C3%A1s-registado-neste-site",
        "aria-label": "se ainda não estás registado neste site permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Se ainda `}
      <strong {...{
        "parentName": "h2"
      }}>{`não`}</strong>
      {` estás registado neste site`}</h2>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Carrega em 'Iniciar Sessão'`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Carrega em 'Sign Up'`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Coloca o teu primeiro e último nome`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Coloca o teu email`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Coloca uma password que não te esqueças`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Carrega em 'Sign Up'`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Acede ao teu email`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Confirma o registo através do email After School que acabou de chegar`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Aguarda um email nosso`}</strong>
        {` a indicar que já tens acesso ao site (pode demorar até 36 horas)`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Volta ao site`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Carrega em 'Iniciar Sessão' (se a sessão estiver aberta, clica em 'Terminar Sessão' antes de carregar em 'Iniciar Sessão')`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Coloca o teu email`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Coloca a tua password`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Carrega em 'Log in'`}</li>

    </ul>
    <p>{`A partir deste momento, tens acesso à informação do site. Tudo o que está no separador 'Geral' fica
desbloquado. O que está no separador 'Curso' será desbloqueado progressivamente, à medida que o curso
avançar.`}</p>
    <p>{`Por vezes, o site não atualiza automaticamente. Por isso, poderá ser necessário que forces o teu browser
a atualizar a página.`}</p>
    <h2 {...{
      "id": "se-já-estás-registado-neste-site",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#se-j%C3%A1-est%C3%A1s-registado-neste-site",
        "aria-label": "se já estás registado neste site permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Se já estás registado neste site`}</h2>
    <p>{`Tens acesso aos seguintes links:`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Funcionamento.`}</strong>
        {` Explica como funciona o curso. Leitura obrigatória!`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Configurações.`}</strong>
        {` Conjunto de instruções relativas a configurações que tens de fazer para poder participar.`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Calendário.`}</strong>
        {` Calendário do curso.`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Avaliação.`}</strong>
        {` Detalha o nosso procedimento de avaliação. Spoiler alert: não há avaliação.`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`Aula X.`}</strong>
        {` Há um link por aula. Estes links vão sendo desbloqueados à medida que o curso avança.`}</li>

    </ul>
    <p>{`Qualquer dúvida, podes sempre entrar em contacto connosco através do `}
      <a href="https://forum.treetree2.school" target="_blank">{`fórum`}</a>
      {` ou do email
`}
      <a {...{
        "href": "mailto:afterschool@treetree2.org",
        "parentName": "p"
      }}>{`afterschool@treetree2.org`}</a>
      {`.`}</p>
    <p>{`Boas aprendizagens!`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      